<template>
  <div class="composant-details-lieu">
    <p class="nom-lieu">
      {{ nomLieu }}
    </p>
    <div class="frame-depart-retour">
      <div class="frame-details-titre">
        <p class="nombre-numeros">
          Départ<span class="icone-etat-lieu">{{
            etatLieu(UNITE_FESTIVAL_DEPART)
          }}</span>
        </p>
      </div>
      <div class="frame-details-titre">
        <p class="nombre-numeros">
          Retour<span class="icone-etat-lieu">{{
            etatLieu(UNITE_FESTIVAL_RETOUR)
          }}</span>
        </p>
      </div>
    </div>
    <div class="frame-details-container">
      <div
        class="frame-details-stock-numero element-clickable"
        @click="
          () =>
            $emit('afficher-lieu', {
              idLieu: lieu.id_lieu,
              typeUniteFestival: UNITE_FESTIVAL_DEPART,
            })
        "
      >
        <div class="frame-nombre-numeros">
          <img
            alt=""
            class="edit-image"
            src="https://static.overlay-tech.com/assets/ad6abfd3-d239-4a44-987f-fb67549489ce.png"
          />
          <p class="nombre-numeros">
            {{ nombreNumerosEmmenes }}
          </p>
        </div>
        <div class="frame-nombre-numeros">
          <img
            alt=""
            class="edit-image"
            src="https://static.overlay-tech.com/assets/022bf452-1b4e-4bd4-b1d8-e06c8147cfb0.png"
          />
          <p class="nombre-numeros">{{ totalEmmenes }}</p>
        </div>
      </div>
      <div class="separateur"></div>
      <div
        class="frame-details-stock-numero element-clickable"
        @click="
          () =>
            $emit('afficher-lieu', {
              idLieu: lieu.id_lieu,
              typeUniteFestival: UNITE_FESTIVAL_RETOUR,
            })
        "
      >
        <div class="frame-nombre-numeros">
          <img
            alt=""
            class="edit-image"
            src="https://static.overlay-tech.com/assets/8307732a-8cb3-4c02-9768-98d149fb2311.png"
          />
          <p class="nombre-numeros">
            {{ nombreNumerosRamenes }}
          </p>
        </div>
        <div class="frame-nombre-numeros">
          <img
            alt=""
            class="edit-image"
            src="https://static.overlay-tech.com/assets/ada74530-931a-4e39-9403-6256391cebff.png"
          />
          <p class="nombre-numeros">{{ totalRamenes }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { UNITE_FESTIVAL_DEPART, UNITE_FESTIVAL_RETOUR } from "../js/storeConst";
export default {
  name: "ComposantDetailsLieu",
  props: {
    lieu: { default: { nom_lieu: "Aucun" } },
  },
  computed: {
    ...mapState(["UNITE_FESTIVAL_DEPART", "UNITE_FESTIVAL_RETOUR"]),
    nombreNumerosEmmenes() {
      return this.lieu.nbreNumerosEmmenes + " Num.";
    },
    nombreNumerosRamenes() {
      return this.lieu.nbreNumerosRamenes + " Num.";
    },
    nomLieu() {
      return this.lieu.nom_lieu;
    },
    totalEmmenes() {
      return this.lieu.totalEmmenes + " Ex.";
    },
    totalRamenes() {
      return this.lieu.totalRamenes + " Ex.";
    },
  },
  methods: {
    etatLieu(typeUnite) {
      return typeUnite == UNITE_FESTIVAL_DEPART
        ? this.lieu.nombreUnitesDeverrouilleesDepart == 0
          ? "✔️"
          : "🕒"
        : this.lieu.nombreUnitesDeverrouilleesRetour == 0
        ? "✔️"
        : "🕒";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-details-lieu {
  background-color: $fond-page;
  border-radius: 11px;
  padding: 8px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nom-lieu {
  color: $couleur-texte-fonce;
  margin-bottom: 7px;
  @include typo-medium;
}

.icone-etat-lieu {
  @include typo-small;
  margin-left: 3px;
  // position: relative;
  // bottom: 2px;
}
.frame-depart-retour {
  margin-bottom: 7px;
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}
.frame-details-titre {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  // &:not(:last-of-type) {
  //   // margin-right: 17px;
  // }
}
.nombre-numeros {
  color: $couleur-texte-fonce;
  @include typo-festival;
}

.frame-details-container {
  width: 100%;
  display: flex;
  // align-items: flex-start;
  justify-content: space-around;
}
.frame-details-stock-numero {
  // margin-right: 17px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // width: 90px;
}
.frame-nombre-numeros {
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}
.edit-image {
  width: 17px;
  height: 17px;
  margin-right: 7px;
}
.separateur {
  // margin-right: 17px;
  align-self: stretch;
  border: 1px solid $couleur-conteneur-barre-progression;
}
</style>
